import React from 'react';
// import logo from './logo.svg';
// import './App.css';

function App() {
  return (
    <div className="App">
        
          <b>KB</b>
        
      </div>
  );
}

export default App;
